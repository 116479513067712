<a
    class="flex h-full flex-col bg-white md:min-h-[460px]"
    [hyundaiRouterLink]="link()"
    (click)="$event.stopPropagation()"
>
    <div
        class="flex aspect-[100/75] max-h-[580px] w-full shrink-0 items-center justify-center overflow-hidden border-b-1 border-grey-200 bg-grey-500 text-grey-400 xl:min-h-[292px]"
    >
        @if (!imageError() && image(); as image) {
            <ui-m000-c010-image
                class="contents"
                [alt]="vehicle().name + ' Vorschau Bild'"
                [image]="image"
                [highRes]="true"
                (onError)="imageError.set(true)"
                priority="false"
                classList="w-full"
            />
        } @else {
            Derzeit kein Bild vorhanden
        }
    </div>
    <div class="flex h-full flex-1 cursor-pointer flex-col px-24 py-12">
        <p class="line-clamp-2 break-words text-18 font-medium leading-27">{{ vehicle().name }}</p>
        <p class="mb-12 text-16 leading-27">{{ tags() }}</p>
        <div class="mb-auto flex h-full flex-1 flex-col justify-between gap-8">
            <div class="flex min-h-[15px] flex-wrap items-center gap-12">
                @if (vehicle().meta.promise) {
                    <button
                        class="flex items-center gap-6 bg-accent px-8 py-2 text-12 text-white"
                        (click)="openPromiseOverlay(); $event.preventDefault(); $event.stopPropagation()"
                    >
                        Hyundai Promise
                        <i class="icon-info"></i>
                    </button>
                }
                @if (vehicle().meta.batteryCertified) {
                    <button
                        class="flex items-center gap-6 bg-grey-200 px-8 py-2 text-12 text-black"
                        (click)="openBatteryOverlay(); $event.preventDefault(); $event.stopPropagation()"
                    >
                        Batterie Zertifikat
                        <i class="icon-info"></i>
                    </button>
                }
            </div>

            <div class="mt-auto flex flex-grow flex-col justify-between pb-12">
                <span class="flex items-center gap-6 text-16 font-medium text-accent">
                    <i class="icon-location"></i>
                    {{ vehicle().location.city }}
                </span>
                <p class="leading-21 flex items-center text-14">
                    <span>
                        {{ vehicleEnvkv() }}
                    </span>
                </p>
            </div>
        </div>
        <div class="leading-22 mt-auto border-t border-grey-200 pt-8 font-headline text-16 font-medium">
            {{ vehicle().price | currency: 'EUR' : '' : '1.0-0' }} EUR
        </div>
    </div>
</a>
