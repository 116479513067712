import { CurrencyPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ApiTypes, getVehicleEnvkvText, Platform, PLATFORM_TOKEN, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { HyundaiImage, M000C010ImageComponent } from '@hyundai/ui';
import { DialogService } from '@pixelgenau/ngx-dialog';
import { switchMap } from 'rxjs';
import { BatteryOverlayComponentDialogTag } from '../../overlays/battery-overlay/battery-overlay.dialog';
import { PromiseOverlayComponentDialogTag } from '../../overlays/promise-overlay/promise-overlay.dialog';

@Component({
    standalone: true,
    templateUrl: './vehicle-card.component.html',
    selector: 'lib-vehicle-card',
    imports: [CurrencyPipe, RouterLinkDirective, M000C010ImageComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VehicleCardComponent {
    route = inject(ActivatedRoute);
    protected readonly dialogService = inject(DialogService);
    readonly cdr = inject(ChangeDetectorRef);
    destroyRef = inject(DestroyRef);
    vehicle = input.required<ApiTypes.Vehicle>();
    platform = inject(PLATFORM_TOKEN);

    readonly imageError = signal(false);

    tags = computed(() => {
        return this.vehicle().tags.join(' | ');
    });

    image = computed(() => {
        const img = this.vehicle().image;
        if (!img) return null;

        return {
            image: img,
            desktop: { width: 390 },
            tablet: { width: 466 },
            mobile: { width: 703 },
        } satisfies HyundaiImage;
    });

    link = computed(() => {
        const base = `/fahrzeugbestand/${this.vehicle().slug}`;
        if (this.platform === Platform.HCM) return base;

        const dealerSlug = this.route.snapshot.pathFromRoot[1].params['slug'];

        return `/${dealerSlug}${base}`;
    });

    vehicleEnvkv = computed(() => {
        const vehicle = this.vehicle();
        return getVehicleEnvkvText(vehicle.isNew, vehicle.shortEnvkv, vehicle.fuel, vehicle.isPluginHybrid);
    });

    async openPromiseOverlay(): Promise<void> {
        this.dialogService
            .openDialog(PromiseOverlayComponentDialogTag)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((result) => result.close$),
            )
            .subscribe(() => {
                // MW vorrübergehend genehmigt
                // eslint-disable-next-line @rx-angular/no-explicit-change-detection-apis
                this.cdr.detectChanges();
            });
    }

    async openBatteryOverlay(): Promise<void> {
        this.dialogService
            .openDialog(BatteryOverlayComponentDialogTag)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((result) => result.close$),
            )
            .subscribe(() => {
                // MW vorrübergehend genehmigt
                // eslint-disable-next-line @rx-angular/no-explicit-change-detection-apis
                this.cdr.detectChanges();
            });
    }
}
